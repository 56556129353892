import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
const WorkProcessOne = () => {
  const swiperParams = {
    spaceBetween: 20,
    slidesPerView: 1,
    modules: [Navigation],
    navigation: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <>
      {/* work process  */}
      <div className="work__area-2">
        <div className="container">
          <div className="counter__content-2 work__header-2 p-0">
            <div className="service__sectiontitle" data-aos="fade-up">
              <span>EĞİTİM TÜRLERİMİZ</span>
              <h2 className="title">
              GÜVENLİKTE UZMANLAŞAN YYS EĞİTİM PROGRAMLARI
             
              </h2>
            </div>
            <div
              className="work__icon-2"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="swiper-img-prev size"></div>
              <div className="swiper-img-next size"></div>
            </div>
          </div>
          <div className="work__wrapper">
            <div className="swiper workProcess-2">
              <div className="swiper-wrappers">
                <Swiper {...swiperParams}>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="work__item"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="work__thumb">
                          <img
                            src="assets/imgs/home-2/process-1.png"
                            alt="thumb"
                          />
                        </div>
                        <div className="work__contentwrap">
                          <h2 className="work__ititle">
                          YYS Güvenlik Eğitimleri
                          </h2>
                          <span className="work__icounter">01</span>
                        </div>
                        <p>
                        Gümrük Bakanlığı tarafından sunulan YYS, firmalardan belirli güvenlik şartlarını yerine getirmelerini ister ve uygun olanlara kolaylıklar sağlar. Güvenlik eğitimi, bakanlık standartlarına uygun uzmanlar tarafından verilir.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="work__item"
                        data-aos="fade-up"
                        data-aos-delay={600}
                      >
                        <div className="work__thumb">
                          <img
                            src="assets/imgs/home-2/process-2.png"
                            alt="thumb"
                          />
                        </div>
                        <div className="work__contentwrap">
                          <h2 className="work__ititle">
                          Profil İnceleme Eğitimleri
                          </h2>
                          <span className="work__icounter">02</span>
                        </div>
                        <p>
                        SecuriTR; Bir kişinin size yalan söyleyip söylemediğini anlamak için bilimsel yöntemler kullanılarak rutin dışı hareketi yakalamak ve şüpheyi ortaya çıkarmak için hangi yöntemlerin kullanılması gerektiğini öğreten eğitimdir.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="work__item"
                        data-aos="fade-up"
                        data-aos-delay={900}
                      >
                        <div className="work__thumb">
                          <img
                            src="assets/imgs/home-2/process-3.png"
                            alt="thumb"
                          />
                        </div>
                        <div className="work__contentwrap">
                          <h2 className="work__ititle">
                          VIP Koruma Eğitimleri
                          </h2>
                          <span className="work__icounter">03</span>
                        </div>
                        <p>
                        SecuriTR; Özel nitelikli koruma prensipleri ile VIP kişinin risklerini değerlendirerek bu risklere göre VIP koruma personellerine verilen eğitimdir.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="work__item"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="work__thumb">
                          <img
                            src="assets/imgs/home-2/process-3.png"
                            alt="thumb"
                          />
                        </div>
                        <div className="work__contentwrap">
                          <h2 className="work__ititle">
                          Taktik Atış Eğitimleri
                          </h2>
                          <span className="work__icounter">04</span>
                        </div>
                        <p>
                        SecuriTR; Uzman atış hocalarıyla başlangıç, orta seviye, ileri seviye, taktik atış ve çatışma alanı simülasyonlarıyla herhangi bir bireyi atıcılık konusunda ihtisas sahibi yapmaktadır.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="work__item"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="work__thumb">
                          <img
                            src="assets/imgs/home-2/process-1.png"
                            alt="thumb"
                          />
                        </div>
                        <div className="work__contentwrap">
                          <h2 className="work__ititle">
                          Terör Farkındalık Eğitimleri
                          </h2>
                          <span className="work__icounter">05</span>
                        </div>
                        <p>
                        SecuriTR; Dünya geneli tehdit eden terör kuşağına karşı terörün geliş biçimi, hedef seçimi ve kullandığı yöntemlere karşı genel bir farkındalık oluşturmak için sivil unsurlara verilen eğitimdir.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /work process  */}
    </>
  );
};

export default WorkProcessOne;
