import React from "react";

const ProjectGroup = () => {

  const images = [
    "/assets/imgs/logo/cyberrobotic.jpg",
    "/assets/imgs/logo/cok-yasar.jpg",
    "/assets/imgs/logo/cw-enerji.jpg",
    "/assets/imgs/logo/bogazici-universitesi-logo.png",
    "/assets/imgs/logo/yumak.jpg",
    "/assets/imgs/logo/sumer-logo.jpg",
    "https://www.oslo.com.tr/controls/tema/default/assets/images/logo.png",
    "https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/0005/1725/brand.gif?itok=797lu86P",
    "https://logowik.com/content/uploads/images/hyundai-glovis4987.jpg",
    "https://pbs.twimg.com/profile_images/1352260220056170497/_LWTp1G__400x400.jpg",
    "https://assets-global.website-files.com/641da6d8015d0b53412ed344/6477b5519635f86373d52a85_sahinler.png",
    "https://www.ilav.org/wp-content/uploads/2022/12/ilav-logo-beyaz.jpg",
    "https://media.licdn.com/dms/image/C4D0BAQHOR47fSUHOfg/company-logo_200_200/0/1630573630720/yilmaz_kimya_logo?e=2147483647&v=beta&t=jDp68nCZ-UvRDlfUThGaH7w314gcL1vhMr7vb9qh2HM",
    "https://www.teknopanel.com.tr/Upload/NewsImage/Orginal/87adee8c8205485db6e0bf5646729235-NewsImage.png",
    "https://pbs.twimg.com/profile_images/1638913902623719424/mHmJ06tC_400x400.jpg",
    "https://www.yuzde100yerli.com/wp-content/uploads/2014/11/yildiz-holding-logo-1.jpg",
    "https://upload.wikimedia.org/wikipedia/commons/e/ed/TÜMOSAN_logo.svg",
    "https://logowik.com/content/uploads/images/groupe-seb9125.jpg",
    "https://media.licdn.com/dms/image/C4E0BAQEUmrZgQApRCg/company-logo_200_200/0/1630606232352/gliplik_eremet_tekstil_a__logo?e=2147483647&v=beta&t=TZHjFxe2tO0_xAO1mst5bqoarq1tHWWfEDOTpyoumbU",
    "https://seeklogo.com/images/G/Global_Gida-logo-DF40913153-seeklogo.com.png",
    "https://www.kap.org.tr/SirketLogo/8acae2c580e91030018105632b470bcd",
    "https://elslog.se/wp-content/uploads/2022/09/logo.png",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQzDhgCsEWWHB_w12FSftH4vTv3Qd-9Xqcww1r4gO2tw&s",
    "https://1000logos.net/wp-content/uploads/2020/03/Decathlon-Logo.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/BSH_Bosch_und_Siemens_Hausgeräte_logo.svg/2560px-BSH_Bosch_und_Siemens_Hausgeräte_logo.svg.png",
    "https://plturkey.org/wp-content/uploads/2022/11/BAHÇIVAN-Gıda-San.ve-Tic.A.Ş.jpg",
    "https://www.papnews.com/wp-content/uploads/2014/05/Aktul-logo.jpg",
    "https://www.ayvaz.com/basinodasi/thumb/ayvaz_logo.jpg",
    "https://www.akcelik.com.tr/wp-content/uploads/2023/03/akcelik-2.webp",
    "https://logos-world.net/wp-content/uploads/2023/03/Abbott-Logo.png",
    "https://media.licdn.com/dms/image/C4E0BAQGUmj2gv5Kwtg/company-logo_200_200/0/1631331057867?e=2147483647&v=beta&t=9jLUbLUP2vUFBgtAWp30ABSjXuCWlfweeqVYYBToNi0",
    "https://logowik.com/content/uploads/images/522_torku_logo.jpg",
    "https://capitalweb.blob.core.windows.net/lists-large/olmuksan-logo.jpg",
    "https://static.wixstatic.com/media/ce499c_2cb0b2cce19441239d37b0db0a7cbada~mv2.png/v1/fill/w_980,h_274,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/netlog-lojistik-logo.png",
    "https://i.pinimg.com/474x/d3/de/af/d3deafd2f298477766fa7c0b7629f833.jpg",
    "https://soyyigit.com/Assets/Images/190x16010594141976555.jpg",
    "https://www.kisbu.com/wp-content/uploads/2020/12/aaaa.png",
    "https://madduckjewels.com/wp-content/uploads/2019/07/KERRY-express-logo-450x450.jpg",
    "https://www.kap.org.tr/SirketLogo/4028e4a1416e696301416ec5743e289d",
    "https://pictures.kare-design.com/4/KARE-43084-700x700.jpg",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/IMCD_logo.svg/1280px-IMCD_logo.svg.png",
    "https://images-platform.99static.com/TQOkLG6ShjBPIjI88SeIyILoo7Q=/500x500/top/smart/99designs-contests-attachments/38/38464/attachment_38464910",
    "https://cdn.worldvectorlogo.com/logos/goodyear-8.svg",
    
  
  ];

  return (
    <>
      <section className="portfolio__area-6 pt-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="portfolio__list-6 ">
                {images.map((imageUrl, index) => (
                  <div key={index} className="portfolio__item-4">
                    <div className="shine ">
                      <img src={imageUrl} alt={`Referanlarimiz-${index}`} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectGroup;
