import React from "react";

const AboutSix = () => {
  return (
    <>
      {/* About area start */}
      <section className="about__area-3 pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="about__imgs-3" data-aos="fade-up">
                <img src="assets/imgs/hero/yys_belge-scaled.jpg" alt="securitr" />
                
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div
                className="about__content-3"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <h2 className="sec-title text-capitalize">
                YYS HİZMETLERİ
                </h2>
                <p>YYS Hizmetleri, müşterilerinin güvenlik konusundaki ihtiyaçlarını anlamak ve en uygun çözümleri sunmak adına geniş bir uzmanlık yelpazesi ile hizmet vermektedir. Güvenliği ön planda tutan yaklaşımımız ile müşterilerimize güvenilir, etkili ve özelleştirilmiş çözümler sunmaya devam ediyoruz.</p>
                <ul>
                  <li> YYS Güvenlik Eğitimleri </li>
                  <li>YYS Risk Analizleri </li>
                  <li> Fiziki Güvenlik Hizmetleri</li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About area end */}
    </>
  );
};

export default AboutSix;
