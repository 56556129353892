import React, { Suspense } from "react";
import Preloader from "../elements/Preloader";
import HeaderOne from "../components/HeaderOne";
import Breadcrumb from "../components/Breadcrumb";
import Cerez from "../components/Cerez"
import FooterThree from "../components/FooterThree";

const CerezPage = () => {
  
  return (
    <>
      <Suspense fallback={<Preloader />}>
        <HeaderOne />
        <main>
          <Breadcrumb title="Çerez Politikası" />
          <Cerez />
        </main>
        <FooterThree />
      </Suspense>
    </>
  );
  

};


export default CerezPage;
