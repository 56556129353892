import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
const AboutFour = () => {
  return (
    <>
      {/* About area start */}
      <section className="about__area-3 pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="about__imgs-3" data-aos="fade-up">
                <img src="assets/imgs/hero/sec3.jpeg" alt="securitr" />
                <div className="projects">
                  <img src="assets/imgs/icon/user.png" alt="Medal Icon" />

                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <>
                          <p>
                            <span className="d-flex">
                              <span className="counter_slow">
                                <CountUp delay={0} start={0} end={100} />
                              </span>
                              <span>+</span>
                            </span>
                           Referans Ağı
                          </p>
                        </>
                      )
                    }
                  </TrackVisibility>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div
                className="about__content-3"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <h2 className="sec-title text-capitalize">
                Güvenliğe İnovasyonla Adım Atın
                </h2>
                
                <ul>
                  <li>Yüzde yüz milli kaynak ve yatırımla kurulmuş olan SecuriTR’nin tüm dünyada yürütülen projelerinin koordinasyon merkezi Türkiye’dir.</li>
                  <li>SecuriTR’nin sorunlar karşısında geliştirdiği çözümler, milli imkan ve kabiliyetler esas alınarak uygulanır ve bu çözümlerin menşeinde uluslararası tecrübeler yatar.</li>
                  <li>SecuriTR’nin müşterileri ile olan tüm ilişkisi, Kişisel Verilerin Korunması Kanunu ve SecuriTR Etik Kurallar çerçevesinde değerlendirilerek ‘‘SIR’’ niteliğinde ele alınır.</li>
                </ul>
                <div className="about__btn-3">
                  <Link className="db-btn-border btn-rollover" to="/about">
                    Daha Fazla <i className="fa-solid fa-arrow-right" />
                  </Link>
                  <Link className="db-btn-call" to="callto:+90 544 915 28 28">
                    <span>
                      <i className="fa-solid fa-phone" />
                    </span>{" "}
                    +90 544 915 28 28
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About area end */}
    </>
  );
};

export default AboutFour;
