import React from "react";

const AboutSeven = () => {
  return (
    <>
      {/* About area start */}
      <section className="about__area-3 pt-150 pb-150">
        <div className="container">
          <div className="row">
          <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="about__imgs-3" data-aos="fade-up">
                <img src="assets/imgs/hero/sec5.jpeg" alt="securitr" />
                
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div
                className="about__content-3"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <h2 className="sec-title text-capitalize">
                FİZİKİ GÜVENLİK
                </h2>
                <p>SecuriTR; Soğukkanlı ekibi ve kullanmakta olduğu son teknoloji güvenlik ekipmanlarıyla, yaşadığınız ortamı korumakta; birey ya da toplum olarak huzur içerisinde yaşamanız ve hem iç hem de dış tehditlere karşı korunabilmeniz için hizmet vermektedir.</p>
               
                
              </div>
            </div>
            
          </div>
        </div>
      </section>
      {/* About area end */}
    </>
  );
};

export default AboutSeven;
