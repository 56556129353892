import React from "react";
import { Link } from "react-router-dom";

const ServiceFive = () => {
  return (
    <>
      {/* service area start */}
      <section className="service__area-5" style={{background: "#0E1E2A"}}>
        <div className="container">
          <div className="digibold-section-wrapper-5 text-center">
            <h2 className="section-subtitle-5 " data-aos="fade-up">
            STRATEJİMİZ
            </h2>
            <h3 className="section-title-5 " data-aos="fade-up">
              Crafting Digital Experiences <br /> That Inspire
            </h3>
          </div>
          <div className="service__items-5">
            <div
              className="service__item-5"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="service__thumb-5">
                <img src="assets/imgs/home-5/s1.png" alt="service-icon" />
              </div>
              <div className="service__content-5">
                
                <p>
                İş ortaklarımızın ihtiyaçlarını, beklentilerini ve güvenlik risklerini analiz etmek ve onlara en iyi çözümü sunmak.
                </p>
                
              </div>
            </div>
            <div
              className="service__item-5"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="service__thumb-5">
                <img src="assets/imgs/home-5/s2.png" alt="service-icon" />
              </div>
              <div className="service__content-5">
                
                <p>
                İş ortaklarımızın ihtiyaçlarına uygun çözümler geliştirmek amacıyla akademik ve saha çalışmaları ile sürdürülebilir çözümler üretmek.
                </p>
                
              </div>
            </div>
            <div
              className="service__item-5"
              data-aos="fade-up"
              data-aos-delay={400}
            >
              <div className="service__thumb-5">
                <img src="assets/imgs/home-5/s3.png" alt="service-icon" />
              </div>
              <div className="service__content-5">
                
                <p>
                İnsan ve insanlık ile ilgili her şeyi bir değer olarak görmek ve tüm çalışmaları bu esas doğrultusunda yapmak.
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* service area end */}
    </>
  );
};

export default ServiceFive;
