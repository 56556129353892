import React from "react";
import Marquee from "react-fast-marquee";
const BrandThree = () => {
  return (
    <>
      {/* Brand area start */}
      <section className="brand__area ">
        <div className="brand__inner">
          <div
            className="swiper brand__slider"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <div className="swiper-wrapper">
              <Marquee>
              <div className="swiper-slide">
                  <div className="brand__slide">
                    <img src="assets/imgs/logo/sumer-logo.jpg" width={200} alt="Sümer Plastik" />
                  </div>
                </div>

              <div className="swiper-slide">
                  <div className="brand__slide">
                    <img src="assets/imgs/logo/kanca.png" width={300} alt="" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand__slide">
                    <img src="assets/imgs/logo/gumlink-logo.jpg" width={300} alt="" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand__slide">
                    <img src="assets/imgs/logo/megametal.png" alt="" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand__slide">
                    <img src="assets/imgs/logo/cwt.png" alt="" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand__slide">
                    <img src="assets/imgs/logo/euroturklogo.png" alt="" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand__slide">
                    <img src="assets/imgs/logo/logo-asset-global.png" alt="" />
                  </div>
                </div>
                
                <div className="swiper-slide">
                  <div className="brand__slide">
                    <img src="assets/imgs/logo/i.png" alt="" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand__slide">
                    <img src="assets/imgs/logo/SuperFresh-logo.png"  width={200} alt="Super Fresh" />
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="brand__slide">
                    <img src="https://pmrprojeinsaat.com/wp-content/uploads/2018/03/PMR-Proje-iş-ortakları-tekno-panel.png"   alt="Brand Logo" />
                  </div>
                </div>
                
              </Marquee>
            </div>
          </div>
        </div>
      </section>
      {/* Brand area end */}
    </>
  );
};

export default BrandThree;
