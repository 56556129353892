import React, { Suspense } from "react";
import Preloader from "../elements/Preloader";
import HeaderOne from "../components/HeaderOne";
import Breadcrumb from "../components/Breadcrumb";
import ProjectGroup from "../components/ProjectGroup";
import FooterThree from "../components/FooterThree";

const ProjectPage = () => {
  return (
    <>
      <Suspense fallback={<Preloader />}>
        <HeaderOne />
        <main>
          <Breadcrumb title="Referanslarımız" />
          <ProjectGroup />
        </main>
        <FooterThree />
      </Suspense>
    </>
  );
};

export default ProjectPage;
