import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
const HeroThree = () => {
  return (
    <>
      {/* Hero area start */}
      <section className="hero__area-3">
        <div className="hero__inner-3">
          <div className="hero__gallery">
            <img
              className="image-1 cxufadeUp"
              src="assets/imgs/hero/3/1.png"
              alt="securitr"
            />
            <img
              className="image-2 cxufadeUp2"
              src="assets/imgs/hero/3/2.png"
              alt="securitr"
            />
            <img
              className="image-3 cxufadeUp3"
              src="assets/imgs/hero/3/3.png"
              alt="securitr"
            />
          </div>
          <div className="hero__title-3-wrap">
            <h1 className="hero__title-3 cxufadeUp4">
              {" "}
              <span>Think</span> outside <span>the box</span>{" "}
            </h1>
            <img className="line" src="assets/imgs/shape/line.png" alt="Line" />
          </div>
          <div className="hero__contact-3 cxufadeUp4">
            <Link to="/about"> Get in Touch </Link>
          </div>
        </div>
        <img
          className="shape cxuSpin-slow"
          src="assets/imgs/shape/10.png"
          alt="Shape"
        />
        
      </section>
      {/* Hero area end */}
    </>
  );
};

export default HeroThree;
