import React from "react";
import { Link } from "react-router-dom";

const Cerez = () => {
  return (
    <>
      {/* contact */}
      <section className="contact__area-6 pt-150 pb-150">
        <div className="container">
          <div className="row">
            <h1>Çerez Politikası </h1>
          
                        <p>İşbu Çerez Politikası ve Aydınlatma Metni <strong>(“Politika”)</strong>; &nbsp;SecuriTR Güvenlik Şirketi &nbsp;(“SecuriTR") tarafından yürütülen ve https://securiTR.com.tr/ internet sitesi hakkında <strong>(“İnternet Sitesi”)</strong> çerez kullanımına ilişkin ilkeleri ve “SecuriTR” olarak veri sorumlusu sıfatıyla İnternet Sitesinin tarafımızca işletilmesi sebebiyle; veri işleme amacı, veri kategorileri, verilerin toplanma yöntemi ve hukuki sebebi, kimlere ve hangi amaçlarla aktarılabileceği ve verileriniz ile ilgili haklarınızın neler olduğuna dair beyan ve açıklamalarımızı içermektedir.</p>
                        <p>“SecuriTR, veri sahibinin açık rızasıyla veya Kişisel Verilerin Korunması Kanunu’nun <strong>(“Kanun”)</strong> 5. maddesinde öngörülen durumlarda veri sahibinin açık rızası olmaksızın genel nitelikli verileri işleyebilme hakkına sahip bulunmaktadır.&nbsp; Bu kapsamda tarafınızca verilmiş olan açık rızaya istinaden, internet sitesine girmiş olduğunuz anda; İnternet Sitesi ziyaretçilerinin kullanım deneyimlerini iyileştirmek, “SecuriTR” tarafından sunulan hizmetleri iyileştirmek, İnternet Sitesi’nin kullanıcılar tarafından nasıl kullanıldığını anlayabilmek amaçlarıyla "SecuriTR” tarafından çerez kayıt verileri işlenmektedir.</p>
                        <p>&nbsp;</p>
                        <p>Çerezler (cookie) bir web sitesi tarafından cihazınızda oluşturulan ve isim-değer formatında veri barındıran küçük metin dosyalarıdır. Herhangi bir web sitesini kullandığınız süre boyunca sizin kullanım alışkanlık ve tercihlerinizi tarayıcı üzerinde depolamaktadır. Çerezler hem zamandan tasarruf edebilmek hem de daha iyi bir internet deneyimi sunmak mahiyeti ile kullanılır. Bir web sitesi tarafından oluşturulan çerezler siteye erişim için kullandığınız web tarayıcısı tarafından saklanmakta ve çerezlerin içerdiği bilgilere sadece çerezi oluşturan alan adı (örn. site.org.tr) altında sunulan web siteleri tarafından ve aynı tarayıcıyı kullandığınız takdirde uzaktan erişim mümkündür.&nbsp;</p>
                        <p>Çerezler, günümüzde, web teknolojilerinin önemli bir parçası haline gelmiştir ve temel işlevleri; ziyaretçinin ve tercihlerinin hatırlanması ile cihazın tanınmasıdır. Neredeyse her web sitesinde çerez kullanımı söz konusudur.&nbsp;İnternet Site’mizde çerez kullanılmasının başlıca amaçları aşağıda sıralanmaktadır:</p>
                        <ul>
                            <li>İnternet Sitesini iyileştirmek ve İnternet Sitesi üzerinden yeni özellikler sunmak ve sunulan özellikleri sizlerin tercihlerine göre kişiselleştirmek;</li>
                            <li>İnternet Sitesinin çeşitli özelliklerinin ve işlevlerinin düzgün bir şekilde çalışmasını sağlamak (örn. dinamik içeriği yerleştirmek vb.);</li>
                            <li>İnternet sitemizin ziyaretçilerimiz tarafından nasıl kullanıldığı hakkında en çok tıklanan bağlantılar, en çok ziyaret edilen sayfalar, görüntülenen hata mesajı sayısı gibi, şahsa özel olmayan, genel bilgiler toplamak ve bu bilgileri analiz ederek hatalı sayfaları işler hale getirmek, web sitemizi geliştirmek, tercih edilmeyen sayfaları kaldırmak veya iyileştirmek.</li>
                            <li>İnternet Sitesinden nasıl haberdar olduğunuzu belirlemek,</li>
                            <li>Trafik istatistikleri oluşturmak,</li>
                            <li>İnternet reklamcılığı, Targeting ve re-targeting (=hedefleme ve yeniden hedefleme), çapraz satış, kampanya, fırsat ve ürün/hizmet reklamları gibi size özel ürün ve hizmetlere yönelik fırsatlar sunmak, tercihlerinizi göz önünde bulundurarak önerilerde bulunmak.</li>
                        </ul>
                        <p>Kanun’da da belirtildiği üzere işbu Politika’da belirtilen nitelikteki kişisel verileriniz; Zorunlu Çerezler açısından; “bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması” hükmüne istinaden, Pazarlama, İşlevsel ve Performans Çerezleri’nin anonim veri içermeksizin işlenmesi durumunda ise “ilgili kişinin açık rızası” temin edilerek ilgili hukuki işleme nedenlerine bağlı olarak işlenmektedir.</p>
                        <p>Çerezler bu Politika’da belirtilen amaçlar dışında kullanılmamakta olup tüm ilgili işlemler veri koruma mevzuatına uygun olarak yürütülmektedir.</p>
                        <table>
                            <thead>
                                <tr>
                                    <td width="165">
                                        <p>Çerez Türü</p>
                                    </td>
                                    <td>
                                        <p>Açıklama</p>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>Zorunlu Çerezler</p>
                                    </td>
                                    <td>
                                        <p>İnternet sitesinin düzgün bir şekilde çalışabilmesi, sitenin özelliklerinden ve sunulan hizmetlerden yararlanabilmeniz için kullanımı mecburi olan çerezlerdir.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Performans Çerezleri</p>
                                    </td>
                                    <td>
                                        <p>Kullanıcı davranışlarının analizi amacıyla istatistik ölçüme imkân veren çerezler olarak da belirtilebilir. Ziyaretçi sayısının tahmini, internet sitesi içerisindeki gezinme durumu veya bir internet sayfasına götüren en önemli arama motoru anahtar kelime tespiti gibi amaçlarla işlenmektedir.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Pazarlama Çerezleri</p>
                                    </td>
                                    <td>
                                        <p>Kullanıcıların çevrim içi hareketlerini takip ederek buna göre yine internet ortamında reklam gösterilmesine olanak sağlayan çerezlerdir.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>İşlevsel Çerezler</p>
                                    </td>
                                    <td>
                                        <p>Kişiselleştirme ve tercihlerin hatırlanması amaçları ile kullanılan çerezlerdir.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p><strong>&nbsp;</strong></p>
                        <p>Çoğu tarayıcı internet sitelerindeki çerezleri görüntülemenize, yönetmenize, silmenize ve engellemenize izin verir. Tüm çerezleri temizlerseniz, belirlediğiniz tüm tercihleri kaybetmiş olursunuz. Buna çerezlerin tutulmaması özelliği de dâhildir çünkü bu özelliğin çalışması için cihazınızda bunun için belirlenmiş çerez bulunmalıdır.</p>
                        <p>Tarayıcılarda çerezlerin nasıl kontrol edileceğine ilişkin rehberler aşağıda bulunmaktadır:</p>
                        <p><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en</a></p>
                        <p><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a></p>
                        <p><a href="https://support.apple.com/kb/ph21411?locale=en_US">https://support.apple.com/kb/ph21411?locale=en_US</a></p>
                        <p><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a></p>
                        <p><a href="https://www.adobe.com/tr/privacy/opt-out.html">https://www.adobe.com/tr/privacy/opt-out.html</a></p>
                        <p><span>Bunlara ek olarak çoğu tarayıcı aşağıda yer olan işlemleri yapmanıza olanak tanımaktadır: </span></p>
                        <ul>
                            <li><span> </span><span>Kaydedilmiş çerezleri görüntüleme ve dilediklerini silme</span></li>
                            <li><span> </span><span>Tüm çerezleri engelleme</span></li>
                            <li><span> </span><span>Belli sitelerden çerezleri engelleme</span></li>
                            <li><span> </span><span>Tarayıcınızı kapattığınızda tüm çerezleri silme</span></li>
                            <li><span>Üçüncü taraf çerezleri engelleme</span></li>
                        </ul>
                        <p><strong>&nbsp;</strong></p>
                        <p>Kanun’un 8. maddesi, “kişisel veriler, ilgili kişinin açık rızası olmaksızın aktarılamaz” hükmünü, 8. Maddenin 2. fıkrasının a bendi ise, “kişisel veriler, 5. Maddenin 2. fıkrasında belirtilen şartlardan birinin bulunması halinde, ilgili kişinin açık rızası aranmaksızın aktarılabilir” hükmünü içermektedir. “SecuriTR” tarafından ancak 5. maddenin 2. fıkrasında yer alan hukuki sebeplere bağlı olarak yahut tarafınızca verilmiş olan açık rızaya istinaden; kişisel verileri talep etmeye yetkili kişi, kurum ve kuruluşları ile paylaşılabilmektedir.</p>
                        <p>&nbsp;</p>
                        <p>Kanun kapsamında aşağıda yer alan hususlar hakkında bilgi almak için bizle her zaman iletişime geçebilirsiniz; Kişisel verilerinizin işlenip işlenmediğini öğrenmek, işleme amacını ve amaca uygun olup olmadığını öğrenmek, kişisel verilerin aktarıldığı kişilerin kimler olduğunu bilmek, eksik veya yanlış işleme halinde düzeltme istemek ve şartları gerçekleşmişse kişisel verilerin silinmesini istemek ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini istemek, işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etmek, kanuna aykırı işleme sebebiyle zarara uğraması halinde zararını talep etmek, kişisel verilerin kanuna uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerin silinmesini veya imha edilmesini istemek.</p>
                        <p>&nbsp;</p>
                        <p>Söz konusu haklarınıza yönelik başvurularınızı İnternet Sitesinde bulunan Veri Sahibi Başvuru Formu’nu doldurarak noter kanalıyla veya Kişisel Verilerin Korunması Kurulu tarafından belirlenen diğer yöntemler ile gönderebilir veya KEP adresine güvenli elektronik imzalı olarak iletebilirsiniz. Talebinize en kısa sürede ve kanuna uygun olarak en geç otuz (30) gün içerisinde geri dönüş yapılacaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde, “SecuriTR’nin Kişisel Verileri Koruma Kurulunca belirlenen tarife üzerinden kanuna uygun olarak ücret talep etme hakkı saklıdır.</p>
                        <p>Çerezler ile ilgili daha detaylı bilgi almak isteyen ziyaretçilerimizin&nbsp;<a href="http://www.aboutcookies.org/">www.aboutcookies.org</a> adresini ziyaret etmeleri önerilir.&nbsp;</p>
                        <p><strong>&nbsp;</strong></p>
                    

        </div>
        </div>          
      </section>

      <style>{"tbody, td, tfoot, th, thead, tr { border-width:1px; }"}</style>

      {/* /iletisim */}
    </>
  );
};

export default Cerez;
