import React, { Suspense } from "react";
import Preloader from "../elements/Preloader";
import HeaderOne from "../components/HeaderOne";
import AboutSix from "../components/AboutSix";
import GalleryTwo from "../components/GalleryTwo";
import FooterThree from "../components/FooterThree";
import AboutSeven from "../components/AboutSeven"
import WorkProcessOne from "../components/WorkProcessOne";
import AboutThree from "../components/AboutThree"
import AboutEight from "../components/AboutEight";
import AboutNine from "../components/AboutNine";
import Breadcrumb from "../components/Breadcrumb";
const Services = () => {
  return (
    <>
      <Suspense fallback={<Preloader />}>
      <HeaderOne />
        <main>
        <Breadcrumb title="Hizmetlerimiz" />
          <AboutSix />
          <GalleryTwo />
          <WorkProcessOne/>
          <AboutSeven/>
          <AboutThree/>
          <AboutEight/>
          <AboutNine/>
        </main>
        <FooterThree />
      </Suspense>
    </>
  );
};

export default Services;
