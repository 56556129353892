import React from "react";
import { Link } from "react-router-dom";

const AboutTwo = () => {
  return (
    <>
      {/* About area start */}
      <section className="about__area-6 pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="about__imgs-6">
                <div className="image-1">
                  <img src="assets/imgs/hero/camera.png" alt="securitr" />
                  
                </div>
                <div className="image-2">
                  <img src="assets/imgs/hero/smallpic.png" alt="securitr" />
                </div>
                <div className="bell">
                  <span>
                    <i className="fa-regular fa-bell" style={{color:"white"}}/>
                  </span>
                </div>
                <div className="chart">
                  <span>
                    <i className="fa-solid fa-chart-column" style={{color:"white"}} />
                  </span>
                </div>
                <div className="projects">
                  <img src="assets/imgs/about/6/madalya.png" width={30} alt="Medal Icon" />
                  <p>
                    <span>100+</span>Referans Ağı
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="about__content-6">
                <h2 className="sec-subtitle-2">Neden SECURITR? </h2>
                <h3 className="sec-title underline-2">
                YYS GÜVENLİK HİZMETLERİ
                </h3>
                <p>
               
SecuriTR Güvenlik Şirketi ilk olarak ARMA DEFENCE Company adı altında 2016 yılında İstanbul’da kurulmuştur. İlk kuruluş aşamasında Eğitim ve Danışmanlık şirketi olarak kurulan firma sektörün genel ihtiyaçlarına karşılık vermek amacıyla 2020 yılında Anonim şirket yapısına geçmiş ve Özel Güvenlik Faaliyet izin belgesini alarak sektör tecrübelerini Özel güvenlik alanına kaydırmıştır.
                </p>
                <ul>
                  <li>ARMA Defence döneminde sahip olduğu 72 şirket referansına 38 firmayı daha ekleyerek bu gün toplamda 110 şirkete hizmet veren dev bir referans ağına sahiptir. </li>
                  <li>SecuriTR Eğitim, Danışmanlık ve Özel Güvenlik alanlarında hizmet vermektedir.</li>
                 
                </ul>
                <Link className="db-btn-arrow" to="/iletisim">
                 İLETİŞİM<i className="fa-solid fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/shape/6.png"
          alt="Zigzag Shape"
          className="shape"
        />
      </section>
      {/* About area end */}
    </>
  );
};

export default AboutTwo;
