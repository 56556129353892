import React, { Suspense } from "react";
import Preloader from "../elements/Preloader";
import HeaderFour from "../components/HeaderFour";
import HeroFour from "../components/HeroFour";
import AboutFive from "../components/AboutFive";
import WorkProcessThree from "../components/WorkProcessThree";
import CounterFive from "../components/CounterFive";
import BrandThree from "../components/BrandThree";
import FooterThree from "../components/FooterThree";
import TeamFour from "../components/TeamFour";
import FaqTwo from "../components/FaqTwo";
import ServiceFive from "../components/ServiceFive";
import  FaqOne from "../components/FaqOne";
import AboutFour from "../components/AboutFour";

const HomeFour = () => {
  return (
    <>
      <Suspense fallback={<Preloader />}>
        <HeaderFour />
        <main>
          <HeroFour />
          <AboutFive />
          <CounterFive />
          <AboutFour/>
          <WorkProcessThree />
          <FaqOne/>
          <FaqTwo/>
          <TeamFour/>
          <BrandThree />
        </main>
        <FooterThree />
      </Suspense>
    </>
  );
};

export default HomeFour;
