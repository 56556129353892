import React from "react";

const GalleryTwo = () => {
  return (
    <>
      {/* Gallery area start */}
      <section className="gallery__area-3 pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="gallery__title-wrap-3" data-aos="fade-up">
                <h2 className="sec-title text-capitalize">
              SECURITR EĞİTİM VE DANIŞMANLIK
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <div className="gallery__top-3">
                <div
                  className="gallery__item-3"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <img src="assets/imgs/hero/749.jpg" alt="securitr" />
                </div>
                <div
                  className="gallery__item-3"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <p>

YYS Güvenlik Eğitimleri, Profil İnceleme, VIP Koruma, Taktik Atış ve Terör Farkındalık alanlarında uzmanlaşan programlar sunarak güvenlik personelinin becerilerini artırır. Her eğitim, spesifik beceri setlerini hedefleyerek katılımcılara etkili ve özelleştirilmiş bilgi sağlar.
                  </p>
                  <img src="assets/imgs/hero/sapka.png" alt="securitr" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <div className="gallery__btm-3">
                <div
                  className="gallery__item-3"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                <img src="assets/imgs/hero/2.jpg" alt="securitr" />
                 
                </div>
                <div
                  className="gallery__item-3"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <img src="assets/imgs/hero/novar.jpg" alt="securitr" />
                </div>
                <div
                  className="gallery__item-3"
                  data-aos="fade-up"
                  data-aos-delay={600}
                >
                  <img src="assets/imgs/hero/col3.jpg" alt="securitr" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Gallery area end */}
    </>
  );
};

export default GalleryTwo;
