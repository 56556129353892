import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const TeamFour = () => {
  const swiperRef = useRef();

  return (
    <>
      {/* Team area start */}
      <section className="team__area-5 pt-150 swiper">
        <div className="row">
          <div className="col-lg-4">
            <div className="team__left-5" data-aos="fade-up">
              <h3 className="team__sub-5" style={{ color: "black" }}>
                SON EĞİTİM
              </h3>
              <h2
                className="team__title-5"
                style={{ color: "black", fontSize: 50 }}
              >
                <span>FAALİYETLERİMİZ</span>
              </h2>
              <div
                className="testimonial-five-prev cursor"
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <i className="fa-solid fa-chevron-left" />
              </div>
              <div
                className="testimonial-five-next cursor"
                onClick={() => swiperRef.current?.slideNext()}
              >
                <i className="fa-solid fa-chevron-right" />
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className=" team-active-5">
              <div>
                <Swiper
                  spaceBetween={20}
                  modules={[Navigation]}
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  slidesPerView={1}
                  breakpoints={{
                    768: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                >
<SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/cybernicxfuture.jpg" alt="Member " />
                          <Link
                            to="https://www.instagram.com/securitr.as/p/DBwiCIWsoaW/"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">30 Ekim Çarşamba Günü Japonya 🇯🇵, İtalya 🇮🇹, Türkiye 🇹🇷 İşbirliği Çerçevesinde @mo_istanbul Otelde "Cybernicx Future Geleceğe Adım" Konferansını Gerçekleştirdik</h5>

                          </div>
                      </div>
                    </div>
                  </SwiperSlide>



<SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/bogaz-ici-unv2.jpg" alt="Member " />
                          <Link
                            to="https://www.instagram.com/securitr.as/p/DBMX4XuMMpB"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">17.10.2024 tarihinde Boğaziçi Üniversitesinde Devam Eden Eğitim Faaliyetlerimiz Kapsamında 2. Grup Özel Güvenlik Yönetici ve Personellerine Yönelik "Suçlu Profili İnceleme ve Tespiti Eğitimini" Tarihi Kırmızı Yalıda Gerçekleştirdik</h5>

                          </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  
<SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/cok-yasar.jpg" alt="Member " />
                          <Link
                            to="https://www.instagram.com/p/DA1EIQAMY8k/"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">07.10.2024 Günü Ülkemizin İhracat Devi ÇOKYAŞAR Holdingin İstanbul Silivri'de Bulunan Fabrikasında İlgili Yönetici Personele Yönelik "YYS Güvenlik Eğitimini" Gerçekleştirdik</h5>

                          </div>
                      </div>
                    </div>
                  </SwiperSlide>

<SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/cw-elek.jpg" alt="Member " />
                          <Link
                            to="https://www.instagram.com/p/DAwKek5McBg/"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">Bugün Antalya'mızın En Büyük 4 Sanayi Kuruluşundan Biri Olan CW Enerji Şirketinin Antalya OSB Bulunan Fabrikasında İlgili Personele Yönelik "YYS Güvenlik Eğitimini" Gerçekleştirdik</h5>

                          </div>
                      </div>
                    </div>
                  </SwiperSlide>









            <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/bogaz-ici-unv.jpg" alt="Member " />
                          <Link
                            to="https://www.instagram.com/securitr.as/p/DAGzb3Us3X3/?img_index=1"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">Bugün Boğaziçi Üniversitesi Özel Güvenlik Yönetici ve Personellerine Yönelik "Suçlu Profili İnceleme ve Tespiti Eğitimimizin" Giriş Eğitimi Bağaziçi Üniversitesi Güney Kampüsünde Tamamladık</h5>

                          </div>
                      </div>
                    </div>
                  </SwiperSlide>




<SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/yumak.jpg" alt="Member " />
                          <Link
                            to="https://www.instagram.com/securitr.as/p/C_gJVdysOLU/?img_index=1"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title"> 2023 & 2024 
                          Konya'mızın Güzide Firması @yumakautomotive de 2021 Yılında Gerçekleştirdiğimiz "YYS Güvenlik Eğitimini" Yeni Bir Belge Sürecinde Tekrarlama Fırsatı Bulduk. Dost Seviyesinde Misafirperverlikleri ve Eğitime Katılımları için YUMAK Ailesine Çok Teşekkür Ederim </h5>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>


                   <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/sumer-plastik.jpg" alt="Member " />
                          <Link
                            to="https://www.instagram.com/p/C-vLGdmsZG5/?img_index=1"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title"> Bugün Türkiye'nin İlk YYS Firmalarından  @sumerplastik Sultanbeyli Fabrikada Tekrarlama Eğitimleri Kapsamında İlgili Personele Yönelik "YYS Güvenlik Eğitimini" Gerçekleştirdik </h5>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>


                <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/logo/gumlnk.png" alt="Member " />
                          <Link
                            to="https://www.instagram.com/p/C322JkZsqwN/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title"> Gumlink ilgili personellerine yönelik "YYS Güvenlik Eğitimi" </h5>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={600}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/logo/kancaa.png" alt="Member " />
                          <Link
                            to="https://www.instagram.com/p/C3xzRXJMOks/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">Kanca ilgili Personele Yönelik "YYS Güvenlik Eğitimi" </h5>
                         
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5"
                        data-aos="fade-up"
                      >
                        <div className="team__image">
                          <img src="assets/imgs/logo/osloo.png" alt="securitr" />
                          <Link
                            to="https://www.instagram.com/p/C2NwS7-MGmm/?img_index=2"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">
                          OSLO Gıda Personellere Yönelik YYS Güvenlik Eğitimi
                          </h5>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5"
                        data-aos="fade-up"
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/mega.png" alt="securitr" />
                          <Link
                            to="https://www.instagram.com/p/C03RE92MrPo/?img_index=1"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">
                          Mega Metal Personellere Yönelik YYS Güvenlik Eğitimi
                          </h5>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/cwtinsta.png" alt="securitr" />
                          <Link
                            to="https://www.instagram.com/p/C0w1uRzM3fI/?img_index=2"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">CWT Turkey'de Tamamlanan Güvenlik Eğitimleri</h5>
                          
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={600}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/euro.png" alt="securitr" />
                          <Link
                            to="https://www.instagram.com/p/Cz_mlUIsTQr/?img_index=2"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title"> @euroturkfreight Personellere Yönelik "YYS Güvenlik Eğitimi"</h5>
                          
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5"
                        data-aos="fade-up"
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/assetig.png" alt="Member" />
                          <Link
                            to="https://www.instagram.com/p/Czyyj7qMYxv/?img_index=1"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">
 @assetgli  Personellere Yönelik "YYS Güvenlik Eğitimi"</h5>
                          
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/superfresh.png" alt="Member " />
                          <Link
                            to="https://www.instagram.com/p/CyS3rBUMjC1/?img_index=2"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">Superfresh Personellere Yönelik YYS Güvenlik Eğitimi</h5>
                   
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div
                        className="team__member team-member-5 pt-0"
                        data-aos="fade-up"
                        data-aos-delay={600}
                      >
                        <div className="team__image">
                          <img src="assets/imgs/instagram/popart.png" alt="Member " />
                          <Link
                            to="https://www.instagram.com/p/Cx2eBQistx8/"
                            target="_blank"
                          >
                            <div className="share__wrap">
                              <button className="share-btn">
                                <i className="fa-brands fa-instagram" />
                              </button>
                            </div>
                          </Link>
                        </div>
                        <div className="team__content">
                          <h5 className="team__title">@lalemuzesi Sergisinin Koruma ve Gözetim Görevi</h5>
                         
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                 
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Team area end */}
    </>
  );
};

export default TeamFour;
