import React from "react";
import { Link } from "react-router-dom";

const AboutThree = () => {
  return (
    <>
      {/* About area about */}
      <section className="about__area-2">
        <div className="container">
          <div className="about__wrapper-2">
            <div className="about__item">
              <div className="about__content">
                <h2 className="about__title-2" data-aos="fade-up">
                K9 GÖREV KÖPEKLERİ
                </h2>
                <p
                  className="about__dis-2"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  SecuriTR; Önleyici güvenlik hizmetleri sürecindeki tüm önlemlerin yanında, K9 Görev Köpeklerinden da yararlanmaktadır. Yalnızca bulması gerekli olan yasaklı maddeye odaklanan K9 köpekleri; uyuşturucu ve patlayıcı madde, mayın detektörü̈, bina güvenliği ve kalabalık yönetimi gibi alanlarda herhangi bir fiziksel hasara (ısırma, saldırı vs.) sebebiyet vermeden tam güvenlik sağlamaktadır.
                </p>
                <div
                  className="about__btnwrapper-2 db-arrow-up"
                  data-aos="fade-up"
                  data-aos-delay={600}
                >
                  <Link className="about__arrowup" to="/about">
                  <img
                      className="img"
                      src="assets/imgs/home-2/ok.png"
                      alt="arrowup"
                    />
                    <img
                      className="img-2"
                      src="assets/imgs/home-2/ok.png"
                      alt="arrowup"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="about__item">
              <div
                className="about__thumb"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <img
                  src="assets/imgs/hero/sec4.jpeg"
                  alt="about-thumb"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Hero area about */}
    </>
  );
};

export default AboutThree;
