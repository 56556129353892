import React from "react";
import { Link } from "react-router-dom";

const AboutNine = () => {
  return (
    <>
      {/* About area about */}
      <section className="about__area-2">
        <div className="container">
          <div className="about__wrapper-2">
            <div className="about__item">
              <div className="about__content">
                <h2 className="about__title-2" data-aos="fade-up">
                SİBER GÜVENLİK
                </h2>
                <p
                  className="about__dis-2"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  SecuriTR; Dünya üzerindeki tüm gelişmeleri yakından takip etmekte, hibrit güvenlik sistemleri kurmakta ve güvenlik tehditlerine karşın etkin tedbirler oluşturmaktadır. 
Müşterilerinin; tüm bina, araç ve eşyalarındaki mahrem bilgilerinin güvenliğini dünyadaki en son teknolojik cihazlar ve yetkin personeli ile sağlamaktadır.
                </p>
                <div
                  className="about__btnwrapper-2 db-arrow-up"
                  data-aos="fade-up"
                  data-aos-delay={600}
                >
                  <Link className="about__arrowup" to="/iletisim">
                    <img
                      className="img"
                      src="assets/imgs/home-2/ok.png"
                      alt="arrowup"
                    />
                    <img
                      className="img-2"
                      src="assets/imgs/home-2/ok.png"
                      alt="arrowup"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="about__item">
              <div
                className="about__thumb"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <img
                  src="assets/imgs/instagram/cybersec.jpg"
                  alt="about-thumb"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Hero area about */}
    </>
  );
};

export default AboutNine;
