import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const HeaderTwo = () => {
  const [offCanvas, setOffCanvas] = useState(false);

  useEffect(() => {
    var offCanvasNav = document.getElementById("offcanvas-navigation");
    var offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='menu-expand'><i></i></span>"
      );
    }

    var menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
    var numMenuExpand = menuExpand.length;

    function sideMenuExpand() {
      if (this.parentElement.classList.contains("active") === true) {
        this.parentElement.classList.remove("active");
      } else {
        for (let i = 0; i < numMenuExpand; i++) {
          menuExpand[i].parentElement.classList.remove("active");
        }
        this.parentElement.classList.add("active");
      }
    }

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", sideMenuExpand);
    }
  }, []);

  const offCanvasControl = () => {
    setOffCanvas(!offCanvas);
  };
  return (
    <>
      {/* Offcanvas area start */}
      <div
        className={!offCanvas ? "offcanvas__area" : "offcanvas__area showed"}
      >
        <div className="offcanvas__inner">
          <div className="offcanvas__top">
            <img src="assets/imgs/logo/logo-light.png" alt="Logo" />
            <button id="offcanvas_close" onClick={offCanvasControl}>
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
          <div className="offcanvas__search">
            <form action="#">
              <input type="text" placeholder="Search..." name="s" />
            </form>
          </div>
          <div className="offcanvas__menu">
            <nav className="offcanvas-navigation" id="offcanvas-navigation">
            <ul>
                <li>
                  <Link to="/">Anasayfa</Link>
                </li>
                <li>
                  <Link to="/hakkimizda">Hakkımızda</Link>
                </li>
                <li>
                  <Link to="/referanslarimiz">Referanslarımız</Link>
                </li>
                <li>
                  <Link to="/hizmetlerimiz">Hizmetlerimiz</Link>
                </li>

                <li>
                  <Link to="/iletisim">İletişim</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="offcanvas__map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3006.5775916294356!2d29.06415397626519!3d41.10007507133791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caca6ad13a55df%3A0x64c1195c87c1fed2!2sKanl%C4%B1ca%2C%20Hac%C4%B1%20Muhittin%20Sk.%20No%3A10%2C%2034810%20Beykoz%2F%C4%B0stanbul!5e0!3m2!1str!2str!4v1706080767300!5m2!1str!2str"
              loading="lazy"
              title="securitr"
            />
          </div>
          <div className="offcanvas__btm">
            <div className="footer__address-3">
              <ul>
                <li>
                  <span>
                    <i className="fa-solid fa-location-dot" />
                  </span>
                  <p className="text-white">
                    Burmsille Street, MN 55337, <br />
                    United States
                  </p>
                </li>
                <li>
                  <span>
                    <i className="fa-solid fa-phone" />
                  </span>
                  <div>
                    <Link to="tel:+88014420420">+(1) 123 456 7890</Link>
                    <Link to="tel:+88014420420">+(1) 098 765 4321</Link>
                  </div>
                </li>
                <li>
                  <span>
                    <i className="fa-solid fa-envelope" />
                  </span>
                  <div>
                    <Link to="mailto:info@driller.com">info@driller.com</Link>
                    <Link to="mailto:info.example@driller.com">
                      info.example@driller.com
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            <div className="footer__social-3">
              <ul>
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-facebook-f" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-twitter" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-instagram" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Offcanvas area end */}
      {/* Header area start */}
      <header className="header__area  plr-90">
        <div className="header__inner inner-2">
          <div className="header__logo logo-2">
            <Link to="/">
              <img src="/assets/imgs/logo/logo.png" alt="Site Logo" />
            </Link>
          </div>
          <div className="header__menu">
            <nav className="main-menu">
            <ul>
                <li>
                  <Link to="/">Anasayfa</Link>
                </li>
                <li>
                  <Link to="/hakkimizda">Hakkımızda</Link>
                </li>
                <li>
                  <Link to="/referanslarimiz">Referanslarımız</Link>
                </li>
                <li>
                  <Link to="/hizmetlerimiz">Hizmetlerimiz</Link>
                </li>

                <li>
                  <Link to="/iletisim">İletişim</Link>
                </li>
              </ul>
            </nav>
          </div>
         
        </div>
      </header>
      <div className="search__form">
        <form action="#">
          <input type="text" name="s" placeholder="Search..." />
        </form>
      </div>
      {/* Header area end */}
    </>
  );
};

export default HeaderTwo;
