import React, { Suspense } from "react";
import Preloader from "../elements/Preloader";
import HeaderOne from "../components/HeaderOne";
import Breadcrumb from "../components/Breadcrumb";
import Contact from "../components/Contact"
import FooterThree from "../components/FooterThree";

const ContactPage = () => {
  return (
    <>
      <Suspense fallback={<Preloader />}>
        <HeaderOne />
        <main>
          <Breadcrumb title="İletişim" />
          <Contact />
        </main>
        <FooterThree />
      </Suspense>
    </>
  );
};

export default ContactPage;
