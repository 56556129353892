import React from "react";

const AboutEight = () => {
  return (
    <>
      {/* About area start */}
      <section className="about__area-3 pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="about__imgs-3" data-aos="fade-up">
                <img src="assets/imgs/instagram/vipkoruma.jpg" alt="securitr" />
                
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div
                className="about__content-3"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <h2 className="sec-title text-capitalize">
                VIP YAKIN KORUMA
                </h2>
                <p>SecuriTR; Dünyada sınırlı sayıda bulunan IBSSA (International Bodyguard and Security Services Association) eğitimli korumalarla özel güvenlik çözümleri sunmaktadır. 
Ekibimiz; ilk yardım, terörle mücadele, bomba tehdidi, kaçırma ve hırsızlık üzerine özel eğitimli; esnek, yabancı dil bilen, iletişim becerileri kuvvetli; psikolojik ve zihinsel yetileri yüksek ve aynı zamanda bulunduğu ortama ayak uydurarak en uygun çözümü üretebilen profesyonellerden oluşmaktadır.</p>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About area end */}
    </>
  );
};

export default AboutEight;
