import React from "react";
import { Link } from "react-router-dom";

const Kvkk = () => {
  return (
    <>
      {/* contact */}
      <section className="contact__area-6 pt-150 pb-150">
        <div className="container">
          <div className="row">
            

                        <p><strong>6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AYDINLATMA METNİ</strong></p>
                        <ol>
                            <li><strong>AMAÇ</strong></li>
                        </ol>
                        <p>Kanlıca Mahallesi Hacı Muhittin Sokak No:10 Beykoz/İstanbul adresinde mukim “<strong>SecuriTR Güvenlik Şirketi</strong>’’ <strong>(“SecuriTR”)</strong> olarak temel insan haklarına atfettiğimiz önem ve kişisel verilerin üstün kıymeti göz önüne alınarak KVKK’nın öngörmüş olduğu yükümlülüğü yerine getirmek için işbu Aydınlatma Metni, 6698 Sayılı Kişisel Verilerin Korunması Kanunu kapsamında hazırlanmıştır.</p>
                        <p>KVKK uyarınca, Veri Sorumlusu sıfatıyla SecuriTR, kişisel verisi işlenecek olan 3. kişileri aşağıdaki şekilde bilgilendirmekte ve KVKK’nın 10. maddesinde belirtilen kapsamda aşağıdaki şekilde aydınlatmaktadır.</p>
                        <ol start="2">
                            <li><strong>TANIMLAR</strong></li>
                        </ol>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>Açık Rıza</strong></p>
                                    </td>
                                    <td>
                                        <p>Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rızayı ifade eder.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>SecuriTR</strong></p>
                                    </td>
                                    <td>
                                        <p>Kanlıca Mahallesi Hacı Muhittin Sokak No:10 Beykoz/İstanbul adresinde mukim “SecuriTR Güvenlik Şirketi’’ni ifade eder.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>Çerez (Cookie)</strong></p>
                                    </td>
                                    <td>
                                        <p>Kullanıcıların bilgisayarlarına yahut mobil cihazlarına kaydedilen ve ziyaret ettikleri web sayfalarındaki tercihleri ve diğer bilgileri depolamaya yardımcı olan küçük dosyalardır.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>İlgili Kullanıcı</strong></p>
                                    </td>
                                    <td>
                                        <p>Verilerin teknik olarak depolanması, korunması ve yedeklenmesinden sorumlu olan kişi ya da birim hariç olmak üzere veri sorumlusu organizasyonu içerisinde veya veri sorumlusundan aldığı yetki ve talimat doğrultusunda kişisel verileri işleyen kişilerdir.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>Kişisel Veri</strong></p>
                                    </td>
                                    <td>
                                        <p>Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi ifade eder.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>Özel Nitelikli Veri</strong></p>
                                    </td>
                                    <td>
                                        <p>Irk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık kıyafet, dernek, vakıf ya da sendika üyeliği, sağlık, cinsel hayat, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili veriler ile biyometrik ve genetik veriler.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>Kişisel Verilerin İşlenmesi</strong></p>
                                    </td>
                                    <td>
                                        <p>Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi ifade eder.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>Veri Sorumlusu</strong></p>
                                    </td>
                                    <td>
                                        <p>Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi ifade eder.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>Veri İşleyen</strong></p>
                                    </td>
                                    <td>
                                        <p>Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişiyi ifade eder.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>Veri Sahibi</strong></p>
                                    </td>
                                    <td>
                                        <p>Kişisel verisi işlenen gerçek kişiyi ifade eder.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <ol start="3">
                            <li><strong>KİŞİSEL VERİLERİNİZİN İŞLENME AMAÇLARI, İŞLEDİĞİMİZ KİŞİSEL VERİLERİNİZİN, TOPLANMA YÖNTEMLERİ VE HUKUKİ SEBEPLERİ</strong></li>
                        </ol>
                        <p><strong>&nbsp;</strong></p>
                        <ul>
                            <li>
                                <strong><em> İşlenme Amaçları&nbsp;</em></strong>
                                <ul>
                                    <li>Kişisel verileriniz KVKK’da öngörülen sınırlara riayet edilerek, ŞİRKET esas sözleşmesinde gösterilen amaçları gerçekleştirmek için kullanılacaktır. İşleme amaçları şunlardır;&nbsp;</li>
                                    <li>İş Kanunu, Türk Ticaret Kanunu, Türk Borçlar Kanunu, İmar Kanunu başta olmak üzere mevzuatta öngörülen faaliyetlere ve denetime ilişkin yükümlülüklerin karşılanması,</li>
                                    <li>Şirketimiz tarafından sunulan hizmetlerden yararlanmanız için gereken çalışmaların ilgili birimlerce yapılması,</li>
                                    <li>Bize ulaşmanız halinde tarafımızla paylaştığınız iletişim kanallarınız üzerinden Şirketimiz ve faaliyetlerinin tanıtımı amacıyla sizinle iletişime geçilmesi, Talep, şikâyet veya önerilerinizin kaydedilmesi, değerlendirilmesi ve gerekli incelemelerin yapılması, talep, şikâyet veya önerilerinizin yerine getirilmesi, takip edilmesi ve sonuçlandırılabilmesi, tarafınızla talep, şikâyet veya önerilerinize yönelik iletişim faaliyetlerinin yürütülebilmesi geri dönüşlere yönelik kalite ve destek ölçümlerinin yapılması, müşteri hizmetlerimizi iyileştirme faaliyetlerinin yürütülebilmesi,</li>
                                    <li>Düzenleyici ve denetleyici kurumlarla, resmi mercilerin talep ve denetimleri doğrultusunda gerekli bilgilerin temin edilmesi,</li>
                                    <li>Sistem güvenliğinin sağlanması, denetlenmesi ve geliştirilmesi,</li>
                                    <li>Teknik altyapının sağlanması, geliştirmelerinin ve güncellemelerinin sağlanması,</li>
                                    <li>Sistemsel sorunların tespit edilmesi ve giderilmesi adına çalışmaların yürütülmesi,</li>
                                    <li>Rezervasyon kayıtlarının oluşturulması ve takibi,</li>
                                    <li>KVKK kapsamında yapılacak ilgili kişi başvurularının alınması ve yanıtlanabilmesi</li>
                                </ul>
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <ul>
                            <li>
                                <strong><em> İşlenen Kişisel Veriler</em></strong>
                                <ul>
                                    <li><strong><em>Kimlik Verileri: </em></strong>Adınız ve Soyadınız</li>
                                    <li><strong><em>İletişim Verileri:</em></strong> E-posta adresiniz, cep telefonu numaranız, telefon numaranız ve adresiniz</li>
                                    <li><strong><em>Müşteri İşlem Verisi:</em></strong> Kişisel Mesajınız sekmesindeki mesajınız ve konunuz</li>
                                    <li><strong><em>İşlem Güvenliği Verisi: </em></strong>İnternet sitesi giriş-çıkış saatiniz, IP adresi ve güvenlik kodu bilginiz</li>
                                    <li>Özel nitelikli kişisel veriler diğer kişisel verilere kıyasla daha yüksek bir ihlal riski doğurmaktadır, bu veriler; kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleri ile ilgili verileriyle biyometrik ve genetik verilerdir. Kişisel Mesajınız sekmesindeki yorumlarınız vasıtasıyla bize ulaşırken özel nitelikli kişisel verilerinizi bizimle paylaşmamanızı öneririz. Mesajlarınız kapsamında iletebileceğiniz özel nitelikli kişisel verilerinizi; istek, öneri ve şikayetlerinizin giderilebilmesi amacıyla özgür iradenizle paylaştığınız ve işlenmesine rıza gösterdiğiniz kabul edilecektir.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <ul>
                            <li>
                                <strong><em> Kişisel Verilerinizin Toplanma Yöntemleri</em></strong>
                                <ul>
                                    <li>SecuriTR olarak kişisel verilerinizi, <a href="https://securitr.com.tr/">https://securitr.com.tr/</a> adresini ziyaret etmeniz ile birlikte, Rezervasyon formunu doldurmanız halinde, dijital yollarla (Kanun’da “otomatik olan” yöntem şeklinde ifade edilmektedir.) toplamaktayız.</li>
                                    <li>Bunun yanında ŞİRKET resmi e-mail adresi olan bilgi@dmstar.com adresine veyahut “@securitr.com.tr” uzantısını kullanan SecuriTR’e ait herhangi bir mail adresine, SecuriTR'ne ait KEP adresine kişisel veri gönderilmesi durumunda söz konusu iletişim kanalları vasıtasıyla toplanmaktadır.</li>
                                    <li>Kişisel veriler, fiziken evrak gönderilmesi, SecuriTR sağladığı bir evrakın fiziken doldurulması, suretiyle de toplanmaktadır.</li>
                                    <li>Kişisel verileriniz ayrıca otomatik yollarla https://securitr.com.tr/ adresi ve uzantılarında kullanılan çerezler (cookie) vasıtasıyla da toplanmaktadır. Söz konusu çerezler, yalnızca ziyaretçinin siteyi tam verimlilikte kullanabilmesi için gerekli çerezler olup ziyaretçinin tercihlerini hatırlamak amacıyla kullanılmakta ve başka bir kişisel veri temin etmemektedir.&nbsp; Çerez politikamıza <a href="https://securitr.com.tr/">https://securitr.com.tr/</a> adresinden ulaşabilirsiniz.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <ul>
                            <li>
                                <strong><em>Kişisel Veri İşlemenin Hukuki Sebebi</em></strong>
                                <ul>
                                    <li>Kişisel verileriniz, 3.1. maddede yer alan amaçlarla Kanun’un 5, 8. ve 9. maddelerinde belirtilen ve aşağıda yer verilen hukuka uygunluk sebeplerine dayanılarak, uygun bilgi güvenliği tedbirleri alınarak işlenmektedir.</li>
                                    <li>Sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, kişisel verilerinizin işlenmesinin gerekli olması hukuki sebebine dayanarak; sözleşmenin kurulması, faaliyetlerinin yürütülmesi, rezervasyon işlemlerinizin gerçekleştirilmesine yönelik faaliyetlerin yürütülmesi, SecuriTR’nin iş faaliyetlerinin yürütülmesi ve denetimi, siz müşterilerimize daha iyi hizmet verebilmek amacıyla sizlerden gelecek talep, şikayet ve önerilerin değerlendirilmesi, iletişim faaliyetlerinin yürütülmesi,</li>
                                    <li>Hukuki yükümlülüğün yerine getirebilmesi için SecuriTR tabii olduğu mevzuatta yer alan ve/veya yetkili merciiler tarafından yayınlanan karar, kılavuz ve rehberlerde belirtilen hukuki yükümlülüklerinin yerine getirilmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi, faaliyetlerin mevzuata uygun yürütülmesinin sağlanması, hukuk işlerinin takibi ve yürütülmesi,</li>
                                    <li>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması hukuki sebebine dayanarak; hukuk ve dava işlerinin yürütülmesi,</li>
                                    <li>Temel hak ve özgürlüklerine zarar vermemek kaydıyla şirketimizin meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebine dayanarak; şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,</li>
                                    <li>Kişisel verilerinizi yukarıda yer alan amaçlarla ve yine yukarıda yer alan kapsamla yurt içinde ve yurt dışında bulunan üçüncü kişilere aktarmamız halinde kişisel verilerin güvenliğini sağlamak amacıyla gerekli idari, teknik ve hukuki tedbirlerin alınacağını da önemle belirtmek isteriz. İdari ve teknik tedbirler dahilinde söz konusu verileri mevzuatta öngörülen süreler sonunda siliyor ve/veya anonim hale getiriyoruz.</li>
                                </ul>
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <ol start="4">
                            <li><strong>KİŞİSEL VERİLERİN AKTARIMI</strong></li>
                        </ol>
                        <p>&nbsp;</p>
                        <ul>
                            <li>Kişisel verileriniz bu Aydınlatma Metninde gösterilen amaçlar için ve buradaki vasıtalarla, gerçek kişiler veya özel hukuk tüzel kişileri, hissedarlar, iş ortağı, tedarikçi, yetkili kamu kurum ve kuruluşları, yargı mercileri, infaz mercileri, emniyet birimleri ile sözleşmeli ürün ve veya hizmet alınan tedarikçiler ile paylaşılmaktadır. Paylaşım yapılan tarafları gösteren tablo aşağıdadır:</li>
                        </ul>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>PAYLAŞILAN TARAFLAR&nbsp;</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>PAYLAŞIM ÖRNEKLERİ&nbsp;</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>Hukuken Yetkili Kamu/Özel Kurum veya Kuruluşu&nbsp;</strong></p>
                                    </td>
                                    <td>
                                        <p>Hazine ve Maliye Bakanlığı, İç Denetim Koordinasyon Kurulu, Sayıştay, Yargı ve İnfaz mercileri, diğer kamu ve vakıf Şirketleri, uluslararası programlar için yurtdışında yer alan Şirketler,&nbsp; Noterler gibi resmi kurum ve kuruluşlarla veri paylaşılabilmektedir.&nbsp;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>Tedarikçi/İş Ortağı/Danışman&nbsp;</strong></p>
                                    </td>
                                    <td>
                                        <p>Şirketin faaliyetlerinin tamamlayıcısı niteliğindeki hizmetleri aldığı, işbirliği yaptığı danışman, kuruluş ve taraflarla yürüttükleri faaliyetlerin amaçlarıyla sınırlı olarak veri paylaşılabilmektedir.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong>Hissedarlar</strong></p>
                                    </td>
                                    <td>
                                        <p>Şirket ortağı olması nedeniyle şirketin faaliyetlerinin sürdürülebilmesi ve planlanmasının gerektirdiği ölçüde hissedarlar ile veriler paylaşılabilmektedir.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <ul>
                            <li>Şirket amaçlarını ilgilendirmeyen hiçbir veri aktarımı yapmamaktadır. Örneğin; rızanız doğrultusunda elde etmiş olsak dahi araç plakanıza ait bilgiler yukarıda gösterilen kişi ve kurumlar da dahil olmak üzere hiçbir 3. kişi ile paylaşılmamaktadır. Bu belirlemenin istisnası, söz konusu veriye ilişkin aktarımın mevzuat ile zorunlu kılınması, yahut bir suç soruşturması için mecburi olması veya resmî bir makamca mevzuata dayalı olarak ve gerekçesi gösterilerek talep edilmesidir.</li>
                        </ul>
                        <p>&nbsp;</p>
                        <ol start="5">
                            <li>
                                <strong>İLGİLİ KİŞİNİN HAKLARI</strong>
                                <ul>
                                    <li>Kanun kapsamında;</li>
                                </ul>
                            </li>
                        </ol>
                        <ul>
                            <li>Kişisel Verilerinizin işlenip işlenmediğini öğrenme,</li>
                            <li>Kişisel Verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
                            <li>Kişisel Verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                            <li>Yurtiçinde veya yurtdışında Kişisel Verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
                            <li>Kişisel Verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,</li>
                            <li>KVKK mevzuatında öngörülen şartlar çerçevesinde Kişisel Verilerinizin silinmesini veya yok edilmesini isteme,</li>
                            <li>Yapılan işlemlerin Kişisel Verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                            <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
                            <li>Kişisel Verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde bu zararın giderilmesini talep etme haklarına sahipsiniz.</li>
                        </ul>
                        <p><em>&nbsp;</em></p>
                        <ul>
                            <li>
                                <em> <strong>Haklarınızı nasıl kullanabilirsiniz ?</strong></em>
                                <ul>
                                    <li>Belirtilen haklarınızı kullanmak isterseniz taleplerinizi bize yazılı olarak (örneğin&nbsp;<strong>ihtarname veya iadeli taahhütlü mektup vasıtasıyla)</strong>veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da bize daha önce bildirdiğiniz ve&nbsp;<strong>sistemimizde</strong>&nbsp;kayıtlı bulunan elektronik posta adresinizi kullanmak suretiyle info@securitr.com.tr adresine iletebilirsiniz.</li>
                                    <li>Talebinizi üstte gösterilen yöntemlerden birisini kullanarak tarafımıza iletmeniz durumunda Kanun’un 13/2. maddesi gereğince, talebiniz en geç 30 gün içinde değerlendirilecek ve tarafınıza konuyla ilgili bilgi verilecektir. Eğer talebiniz kabul edilirse, gerekli işlemler derhal veri sorumlusu SecuriTR tarafından yerine getirilecektir.&nbsp;&nbsp;</li>
                                    <li>Talepler kural olarak ücretsiz karşılanır ancak, talebin gereğini yerine getirmek masraf gerektiriyorsa “Veri Sorumlusuna Başvuru Usul ve Esasları Hk. Tebliğ” madde 7’de öngörülen; <em>“İlgili kişinin başvurusuna yazılı olarak cevap verilecekse, 10 sayfaya kadar ücret alınmaz. 10 sayfanın üzerindeki her sayfa için 1 TL işlem ücreti alınabilir. Başvuruya cevabın CD, flash bellek gibi bir kayıt ortamında verilmesi halinde veri sorumlusu tarafından talep edilebilecek ücret kayıt ortamının maliyetini geçemez.”</em> Hükmü gereğince SecuriTR tarafından ücret istenebilecektir.&nbsp;</li>
                                </ul>
                            </li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p><strong><em>&nbsp;</em></strong></p>
           
          </div>
        </div>
      </section>

      <style>{"tbody, td, tfoot, th, thead, tr { border-width:1px; }"}</style>

      {/* /iletisim */}
    </>
  );
};

export default Kvkk;
