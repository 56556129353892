import React from "react";
import { Link } from "react-router-dom";

const ServiceThree = () => {
  return (
    <>
      {/* Service area start */}
      <section className="service__area-3 pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="service__title-wrap" data-aos="fade-up">
                <h3 className="sec-title text-capitalize text-white">
                STRATEJİMİZ
                </h3>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div
                className="service__text-3"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <p>
               
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="service__list-3">
              <div
                className="service__item-3"
                data-aos="fade-up"
                data-aos-delay={400}
              >
                <div className="icon">
                  <img src="assets/imgs/icon/s4.png" alt="Service Icon" />
                </div>
                <div className="service__content-3">
                 
                  <p>
                  İş ortaklarımızın ihtiyaçlarına uygun çözümler geliştirmek amacıyla akademik ve saha çalışmaları ile sürdürülebilir çözümler üretmek.
                  </p>
                 
                </div>
              </div>
              <div
                className="service__item-3"
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div className="icon">
                  <img src="assets/imgs/icon/s5.png" alt="Service Icon" />
                </div>
                <div className="service__content-3">
                  
                  <p>
                  İnsan ve insanlık ile ilgili her şeyi bir değer olarak görmek ve tüm çalışmaları bu esas doğrultusunda yapmak.
                  </p>
                  
                </div>
              </div>
              <div
                className="service__item-3"
                data-aos="fade-up"
                data-aos-delay={800}
              >
                <div className="icon">
                  <img src="assets/imgs/icon/s6.png" alt="Service Icon" />
                </div>
                <div className="service__content-3">
                  
                  <p>
                  İş ortaklarımızın ihtiyaçlarını, beklentilerini ve güvenlik risklerini analiz etmek ve onlara en iyi çözümü sunmak.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Service area end */}
    </>
  );
};

export default ServiceThree;
