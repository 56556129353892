import React from "react";
import { Link } from "react-router-dom";

const FaqTwo = () => {
  return (
    <>
      {/* FAQs area start */}
      <section className="faq__area-3 pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-xl-5">
              <div className="faq__content-3" data-aos="fade-right">
                <h3 className="sec-title text-capitalize">
                YYS Güvenlik Eğitimi Hakkında Sıkça Sorulan Sorular
                </h3>
                <p>
                Eğer hala aklınıza takılan sorular varsa, lütfen bizimle iletişime geçmekten çekinmeyin.
                </p>
                <Link className="db-btn-arrow" to="/iletisim">
                  İLETİŞİM <i className="fa-solid fa-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-xl-7">
              <div className="faq__list-3">
                <div className="accordion" id="accordionExample">
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    <h2 className="" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <span>01</span>
                        YYS güvenlik eğitimi hangi konuları kapsar?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                         
YYS müfredatının istediği Narkotik Maddelerin Tespiti / Nükleer, Biyolojik, Kimyasal (NBC) Maddelerin Tespiti / Kaçakçılık, Araç Zula Kontrol, Konteynır 7 Noktalı Kontrol / Yanıcı ve Patlayıcı Maddelerin Tespiti konularını kapsamaktadır.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-delay={400}
                  >
                    <h2 className="" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <span>02</span>
                        YYS güvenlik eğitimi katılımcılara ne sunar?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          
Katılımcılar YYS eğitimlerinde YYS süreçleri, sertifikalandırma, ana içeriğin yanında kişiye ait şüpheyi ortaya çıkarma ile eşyaya ait şüpheyi ortaya çıkarma yöntemleriyla alakalı bilgi alırlar.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    <h2 className="" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <span>03</span>
                        SecuriTR YYS güvenlik eğitimindeki uzmanlığı nedir?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        Uzmanlık - İlgili konularda kollukta görev yapmış ve nitelikleri YYS mevzuatında belirtilmiş Akreditasyon sahibi eğitmenler tarafından verilen eğitimler bu güne kadar ki yapılan denetimlerin hepsinden başarıyla geçerek %100 güven sağlamıştır. <br/>
SecuriTR firması, güvenlik sektöründe kaliteli hizmetler sunma konusunda uzmanlaşmış bir kuruluştur ve YYS güvenlik eğitimi gibi önemli bir eğitimi de profesyonel bir şekilde vermektedir.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="shape" src="assets/imgs/shape/11.png" alt="Shape" />
      </section>
      {/* FAQs area end */}
    </>
  );
};

export default FaqTwo;
