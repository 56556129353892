import React from "react";
import { Link } from "react-router-dom";

const WhoWeAreOne = () => {
  return (
    <>
      {/* Who we are area start */}
      <section className="who__area pt-170 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-lg-6">
              <div className="who__left">
                <div className="sec-title-wrap" data-aos="fade-up">
                  <h2 className="sec-subtitle">
                  YYS FİRMALARI İÇİN ANINDA PROFESYONEL HİZMET<span>  YYS FIRMALARI </span>
                  </h2>
                  <p className="sec-title">
                  ŞİRKETİN YYS FİRMALARI İÇİN ÖZEL GÜVENLİK FAALİYETLERİNİN ANINDA PROFESYONEL HİZMET SAĞLADIĞI DİĞER ALANLAR
                  </p>
                </div>
                
              </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-6">
            <div className="who__features">
                  <div
                    className="who__feature"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <div className="number">
                      <span>01</span>
                    </div>
                    <div className="who__content">
                     <Link to={"/hizmetlerimiz"}> <h4 className="title">Özel Güvenlik Hizmetleri</h4></Link>
                    </div>
                  </div>
                  <div
                    className="who__feature"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    <div className="number">
                      <span>02</span>
                    </div>
                    <div className="who__content">
                    <Link to={"/hizmetlerimiz"}>  <h4 className="title">YYS Güvenlik Risk Analiz Raporu</h4></Link>
                    </div>
                  </div>
                  <div
                    className="who__feature"
                    data-aos="fade-up"
                    data-aos-delay={900}
                  >
                    <div className="number">
                      <span>03</span>
                    </div>
                    <div className="who__content">
                    <Link to={"/hizmetlerimiz"}>  <h4 className="title">YYS Güvenlik Eğitimi</h4></Link>
                    </div>
                  </div>
                  <div
                    className="who__feature"
                    data-aos="fade-up"
                    data-aos-delay={1100}
                  >
                    <div className="number">
                      <span>04</span>
                    </div>
                    <div className="who__content">
                 <Link to={"/hizmetlerimiz"}>     <h4 className="title">
   K-9 Görev Köpekleri</h4> </Link>
                    </div>
                  </div>
                  <div
                    className="who__feature"
                    data-aos="fade-up"
                    data-aos-delay={1400}
                  >
                    <div className="number">
                      <span>05</span>
                    </div>
                    <div className="who__content">
                    <Link to={"/hizmetlerimiz"}> <h4 className="title">Siber Güvenlik Hizmetleri</h4></Link> 
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      {/* Who we are area end */}
    </>
  );
};

export default WhoWeAreOne;
