import React from "react";
import { Link } from "react-router-dom";

const FaqOne = () => {
  return (
    <>
      {/* FAQs area start */}
      <section className="faq__area pt-170 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <h2 className="sec-subtitle text-white">
                Kazanım <span>kazanım</span>
              </h2>
              <h3 className="sec-title text-white">
                MALİYET GİDERİCİ <br /> GERİ KAZANIM FORMÜLÜ
              </h3>

              <div className="who__right">
                <div className="who__project">
                  <div className="project-completed">
                    <img src="assets/imgs/who/6/hand.png" width={100} alt="securitr" />
                    <h3 className="total counter_fast">500.000 ₺ </h3>
                    <p>Minimum Kazanç</p>
                  </div>
                </div>
                <img
                  src="assets/imgs/shape/7.png"
                  alt="Shape"
                  className="shape"
                />
              </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="faq__list">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item" data-aos="fade-up">
                    <h2 className="" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                       1- YYS Risk Analizleri
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        YYS Risk Analizi, YYS müfredatına uygun olarak özel niteliklere sahip bir çalışmadır. Firma düzenli olarak risk analizleri yaptırmak için Koruma Tahliye Planları yerine YYS Risk Analizleri yapılmalıdır. YYS Risk Analizleri, özel bir çalışma gerektirir ve uzman personel tarafından yapılmaktadır. YYS risk analizlerinin maliyeti m2 başına 1$ Olarak hesaplanmaktadır. 
<br/>
Kazanım: Minimal bir hesaplamayla 6000 m2 alana sahip bir tesisin YYS Risk Analizi maliyeti
<br/>
<b> 60.000 m2 x 1= 60.000 $ + KDV olacaktır.</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <h2 className="" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                       2- YYS Güvenlik Eğitimi
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        YYS Süreçlerinin ilgili tüm personelin alması zorunlu en önemli basamaklarından olan “YYS Güvenlik Eğitimi” anlaşmanın kabulüyle birlikte ilgili tüm personellere verilecektir. YYS Güvenlik Eğitimi 2024 fiyatı 15.000 ₺’dir. 
                        <br/>
Kazanım: YYS alanında 3 vardiya halinde toplam 45 personelin çalışması durumunda minimum 3 YYS güvenlik eğitimi olacaktır. Bu durumda; 
<br/>
<b> 3 x 20.000 ₺ = 60.000 ₺ + KDV olacaktır.</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    <h2 className="" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                       3- Uzaktan Öğretim Modulü
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        Denetimler sırasında, firmaların YYS eğitimlerini tamamlayan personelin ayrılması ve yerlerine yeni personellerin işe alınmasına rağmen bu yeni personellerin YYS Güvenlik Eğitimi almadan çalıştırıldığı tespit edilmiştir. Firmalar, yeni başlayan personeller için eğitim maliyetini düşük tutmak amacıyla eğitimi ertelemektedir. SecuriTR firması, Ticaret Bakanlığı tarafından kabul edilen "YYS Güvenlik Eğitimi Uzaktan Öğretim Modülü" ile yeni işe başlayan personelin YYS Güvenlik Eğitimini kolaylıkla tamamlamalarını sağlamaktadır. Bu modül, eğitim külfetini azaltarak firmalara yardımcı olmaktadır. 
                        <br/>
Kazanım: YYS alanında 3 vardiya halinde toplam 45 personelin çalışması durumunda çalışan sirkülasyonu farklılık gösterse de yılda minimum 5 personelin şirkete giriş çıkış yaptığını düşünürsek bu personellerin her biri için; 
<br/>
<b> 5 x 20.000 ₺ = 100.000 ₺ + KDV olacaktır.</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-delay={900}
                  >
                    <h2 className="" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                       4- K-9 Narkotik Köpekleriyle Arama
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        SecuriTR YYS Güvenlik Şirketinin doğal yetenekleri arasında yer alan görev köpeklerini anlaşmalı şirketlerin hizmetine sunmaktadır. Fiziki güvenliğini sağladığımız YYS şirketlerimizin talep etmesi halinde şirketin ilgili depolarını 6 ayda bir K-9 Narkotik köpekleriyle aramaktayız. 
                        <br/>
Kazanım: K-9 Narkotik köpekleriyle tek noktada 2 saatlik bir aramanın maliyeti 15.000 ₺ dir. Sene de 2 sefer gerçekleşecek aramanın 2024 yılı fiyatlarıyla; 
<br/>
<b> 2 x 20.000 ₺ = 40.000 ₺ + KDV olacaktır.</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FAQs area end */}
    </>
  );
};

export default FaqOne;
